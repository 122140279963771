// Variable overrides
$body-bg: #ffffff;

$theme-colors: (
        "primary": gray
);

@import "~bootstrap/scss/bootstrap";
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

// ||| Fonts import |||

// Roboto 
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
// Merriweather
@font-face {
  font-family: "Merriweather";
  src: url("../../assets/fonts/Merriweather/Merriweather-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Merriweather";
  src: url("../../assets/fonts/Merriweather/Merriweather-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
// Lato
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Lato-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
// Set dafault font
* {
  font-family: "Roboto";
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

h2 {
  color: blue;
}

.title {
  margin-right: 1em;
}

.backgroundPic {
  background: url('./../../assets/img/icons/a.png') no-repeat center;
  background-size: contain;
  &:hover {
    background-image: url('./../../assets/img/icons/b.png');
  }
}
.right {
  float: right;
  width: 32px;
  height: 32px;
}

/*** Notifications ***/
/*** Example rules ***/
.redux-toastr .toastr {
  border-radius: 0px;
  .rrt-title {
  }
  .rrt-text {
  }
  &.rrt-info, &.rrt-success, &.rrt-warning, &.rrt-error, & button {
  }
  &.rrt-error {
  }
  &.rrt-success {
  }
  &.rrt-warning {
  }
  &.rrt-info {
  }
  .close-toastr {
    opacity: 0.6;
  }
}

.rteTextbox{
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}